.blogafbeelding {
    max-width: 100%;
  }
  
  .loading-indicator {
      display: none;
  }

.bg-meldingstatus {
    background-color:rgb(75,210,143) !important;
}
  